<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'roles'}">Grupos de Permissão</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="container-fluid" v-if="spinner">
					<div class="row d-flex justify-content-center">
						<div class="default-spinner">
							<div class="default-spinner-inner">
								<div></div>
								<div></div>
							</div>
						</div>
					</div>
				</div>

				<div class="container-fluid" v-else>
					<div class="row">
						<div class="col">
							<h5>Informações do Grupo</h5>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-lg-1 col-12">
							<label>Id</label>
							<input
								v-model.trim="role.id"
								class="form-control"
								disabled="disabled"
							/>
						</div>

						<div class="form-group col-lg-4 col-12">
							<label>Nome*</label>
							<input
								v-model.trim="role.name"
								class="form-control"
								v-uppercase
							/>
						</div>
					</div>

					<br>

					<div class="row">
						<div class="col">
							<h5>Permissões</h5>
						</div>
					</div>

					<div class="row">
						<div class="col d-flex flex-wrap">
							<div class="mr-4 mb-4">
								<h5 class="text-center">Categorias de Produto</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label>Visualizar</label>
										<ej-toggle-button
											v-model.trim="categoriesViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Editar</label>
										<ej-toggle-button
											v-model.trim="categoriesUpdateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Criar</label>
										<ej-toggle-button
											v-model.trim="categoriesCreateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Deletar</label>
										<ej-toggle-button
											v-model.trim="categoriesDeleteAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>
								</div>
							</div>

							<div class="mr-4 mb-4">
								<h5 class="text-center">Cidades</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label class="warn">Visualizar qualquer</label>
										<ej-toggle-button
											v-model.trim="citiesViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Visualizar somente <br>das suas regiões</label>
                                        <ej-toggle-button
                                            v-model.trim="citiesViewOwn"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Editar</label>
										<ej-toggle-button
											v-model.trim="citiesUpdateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Criar</label>
										<ej-toggle-button
											v-model.trim="citiesCreateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Deletar</label>
										<ej-toggle-button
											v-model.trim="citiesDeleteAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>
								</div>
							</div>

							<div class="mr-4 mb-4">
								<h5 class="text-center">Configurações</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label>Visualizar</label>
										<ej-toggle-button
											:value="settingsViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
											:disabled="true"
										></ej-toggle-button>
									</div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Editar</label>
                                        <ej-toggle-button
                                            v-model.trim="settingsUpdateAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>
								</div>
							</div>

                            <div class="mr-4 mb-4">
                                <h5 class="text-center">Estados</h5>
                                <div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Visualizar</label>
                                        <ej-toggle-button
                                            :value="statesViewAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                            :disabled="true"
                                        ></ej-toggle-button>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Editar</label>
                                        <ej-toggle-button
                                            v-model.trim="statesUpdateAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Criar</label>
                                        <ej-toggle-button
                                            v-model.trim="statesCreateAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Deletar</label>
                                        <ej-toggle-button
                                            v-model.trim="statesDeleteAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>
                                </div>
                            </div>

							<div class="mr-4 mb-4">
								<h5 class="text-center">Grupos de permissão</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label>Visualizar</label>
										<ej-toggle-button
											v-model.trim="rolesViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Editar</label>
										<ej-toggle-button
											v-model.trim="rolesUpdateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Criar</label>
										<ej-toggle-button
											v-model.trim="rolesCreateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Deletar</label>
										<ej-toggle-button
											v-model.trim="rolesDeleteAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>
								</div>
							</div>

							<div class="mr-4 mb-4">
								<h5 class="text-center">Log</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label>Visualizar</label>
										<ej-toggle-button
											v-model.trim="logViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>
								</div>
							</div>

                            <div class="mr-4 mb-4">
                                <h5 class="text-center">Msg. Programadas</h5>
                                <div>
                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Tudo</label>
                                        <ej-toggle-button
                                            v-model.trim="automessages"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>
                                </div>
                            </div>

<!--							<div class="mr-4 mb-4">-->
<!--								<h5 class="text-center">Monitoramento</h5>-->
<!--								<div>-->
<!--									<div class="d-flex align-items-center justify-content-end">-->
<!--										<label>Tudo</label>-->
<!--										<ej-toggle-button-->
<!--											v-model.trim="monitoringAll"-->
<!--											:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--											class="ml-2"-->
<!--										></ej-toggle-button>-->
<!--									</div>-->
<!--								</div>-->
<!--							</div>-->

							<div class="mr-4 mb-4">
								<h5 class="text-center">Pedidos</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label class="warn">Visualizar qualquer</label>
										<ej-toggle-button
											v-model.trim="view_any_order"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Visualizar os seus</label>
										<ej-toggle-button
											v-model.trim="view_your_own_orders"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label class="warn text-right">Visualizar observações<br>internas</label>
                                        <ej-toggle-button
                                            v-model.trim="view_ps_internal"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <hr>

									<div class="d-flex align-items-center justify-content-end">
										<label class="warn">Criar para qualquer</label>
										<ej-toggle-button
											v-model.trim="create_orders_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Criar para si</label>
                                        <ej-toggle-button
                                            v-model.trim="create_orders_for_yourself"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

									<hr>

									<div class="d-flex align-items-center justify-content-end">
										<label class="warn">Deletar qualquer</label>
										<ej-toggle-button
											v-model.trim="delete_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Deletar qualquer<br> orçamento ou novo</label>
										<ej-toggle-button
											v-model.trim="delete_budget_or_new_orders_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Deletar seus<br> orçamentos ou novos</label>
										<ej-toggle-button
											v-model.trim="delete_budget_or_new_orders_for_yourself"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label class="warn">Editar qualquer <br>(seja dados ou status)</label>
										<ej-toggle-button
											v-model.trim="update_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>
									<div class="d-flex align-items-center justify-content-end">
										<h6>Edição de dados</h6>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Editar dados (exceto status) <br><strong>de qualquer pedido</strong></label>
										<ej-toggle-button
											v-model.trim="update_order_data_except_status_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Editar dados (exceto status) <br><strong>de seus orçamentos ou novos</strong></label>
										<ej-toggle-button
											v-model.trim="update_order_data_except_status_for_yourself"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>
									<div class="d-flex align-items-center justify-content-end">
										<h6>Edição de status</h6>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label class="warn">Tornar novo<br> qualquer</label>
										<ej-toggle-button
											v-model.trim="new_orders_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Tornar orçamento para<br> novo, para qualquer</label>
										<ej-toggle-button
											v-model.trim="update_orders_with_status_budget_to_new_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Tornar orçamento para<br> novo, para si</label>
										<ej-toggle-button
											v-model.trim="update_orders_with_status_budget_to_new_for_yourself"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Rejeitado para novo<br> para qualquer</label>
										<ej-toggle-button
											v-model.trim="update_orders_with_status_rejected_to_new_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Rejeitado para novo<br> para si</label>
										<ej-toggle-button
											v-model.trim="update_orders_with_status_rejected_to_new_for_yourself"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label class="warn">Liberar qualquer</label>
										<ej-toggle-button
											v-model.trim="release_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Liberar qualquer<br> novo ou rejeitado</label>
										<ej-toggle-button
											v-model.trim="release_orders_with_status_new_or_rejected_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label class="warn">Rejeitar qualquer</label>
										<ej-toggle-button
											v-model.trim="reject_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Rejeitar qualquer<br> novo ou liberado</label>
										<ej-toggle-button
											v-model.trim="reject_orders_with_status_new_or_released_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end">
										<label class="warn">Cancelar qualquer</label>
										<ej-toggle-button
											v-model.trim="cancel_orders_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Cancelar qualquer<br> orçamento ou novo</label>
										<ej-toggle-button
											v-model.trim="cancel_budget_or_new_orders_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Cancelar <strong>seus</strong><br> orçamentos ou novos</label>
										<ej-toggle-button
											v-model.trim="cancel_budget_or_new_orders_for_yourself"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end">
										<label class="warn">Lançar qualquer</label>
										<ej-toggle-button
											v-model.trim="launch_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Lançar qualquer liberado</label>
										<ej-toggle-button
											v-model.trim="launch_orders_with_status_released_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label class="warn">Tornar produzido<br> qualquer</label>
										<ej-toggle-button
											v-model.trim="produce_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Tornar produzido<br> qualquer lançado</label>
										<ej-toggle-button
											v-model.trim="produce_orders_with_status_launched_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label class="warn">Expedir qualquer</label>
										<ej-toggle-button
											v-model.trim="ship_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Expedir qualquer<br> produzido</label>
										<ej-toggle-button
											v-model.trim="ship_orders_with_status_produced_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<hr>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label class="warn">Entregar qualquer</label>
										<ej-toggle-button
											v-model.trim="deliver_orders_in_any_case"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end text-right">
										<label>Entregar qualquer<br> expedido</label>
										<ej-toggle-button
											v-model.trim="update_orders_with_status_shipped_to_delivered_for_anyone"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>
								</div>
							</div>

							<div class="mr-4 mb-4">
								<h5 class="text-center">Pessoas</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label class="warn">Visualizar qualquer</label>
										<ej-toggle-button
											v-model.trim="peopleViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Visualizar as suas</label>
                                        <ej-toggle-button
                                            v-model.trim="peopleViewOwn"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <hr>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label class="warn">Editar qualquer</label>
                                        <ej-toggle-button
                                            v-model.trim="peopleUpdateAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Editar as suas</label>
										<ej-toggle-button
											v-model.trim="peopleUpdateOwn"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

                                    <hr>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label class="warn">Criar para qualquer</label>
                                        <ej-toggle-button
                                            v-model.trim="peopleCreateAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"

                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Criar para si</label>
                                        <ej-toggle-button
                                            v-model.trim="peopleCreateOwn"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <hr>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label class="warn">Deletar qualquer</label>
                                        <ej-toggle-button
                                            v-model.trim="peopleDeleteAny"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label>Deletar as suas</label>
                                        <ej-toggle-button
                                            v-model.trim="peopleDeleteOwn"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>
								</div>
							</div>

							<div class="mr-4 mb-4">
								<h5 class="text-center">Produtos</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label>Visualizar <font-awesome-icon v-tooltip="'Visualiza qualquer produto, sem exibir o custo, composição, nem o estoque. Caso deseje visualizar também esses dados, selecione as opções abaixo.'" :icon="['far', 'question-circle']"></font-awesome-icon></label>
										<ej-toggle-button
											v-model.trim="productsViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label class="warn">Visualizar custo</label>
                                        <ej-toggle-button
                                            v-model.trim="productsViewCost"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label class="warn">Visualizar estoque</label>
                                        <ej-toggle-button
                                            v-model.trim="productsViewStock"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <div class="d-flex align-items-center justify-content-end">
                                        <label class="warn">Visualizar composição</label>
                                        <ej-toggle-button
                                            v-model.trim="productsViewComposition"
                                            :labels="{checked: 'Sim', unchecked: 'Não'}"
                                            class="ml-2"
                                        ></ej-toggle-button>
                                    </div>

                                    <hr>

									<div class="d-flex align-items-center justify-content-end">
										<label>Editar</label>
										<ej-toggle-button
											v-model.trim="productsUpdateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Criar</label>
										<ej-toggle-button
											v-model.trim="productsCreateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Deletar</label>
										<ej-toggle-button
											v-model.trim="productsDeleteAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>
								</div>
							</div>

							<div class="mr-4 mb-4">
								<h5 class="text-center">Usuários</h5>
								<div>
									<div class="d-flex align-items-center justify-content-end">
										<label>Visualizar</label>
										<ej-toggle-button
											v-model.trim="usersViewAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Editar</label>
										<ej-toggle-button
											v-model.trim="usersUpdateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Criar</label>
										<ej-toggle-button
											v-model.trim="usersCreateAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>

									<div class="d-flex align-items-center justify-content-end">
										<label>Deletar</label>
										<ej-toggle-button
											v-model.trim="usersDeleteAny"
											:labels="{checked: 'Sim', unchecked: 'Não'}"
											class="ml-2"
										></ej-toggle-button>
									</div>
								</div>
							</div>

<!--							<div class="mr-4 mb-4">-->
<!--								<h5 class="text-center">Tokens</h5>-->
<!--								<div>-->
<!--									<div class="d-flex align-items-center justify-content-end">-->
<!--										<label>Visualizar qualquer</label>-->
<!--										<ej-toggle-button-->
<!--											v-model.trim="tokensViewAny"-->
<!--											:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--											class="ml-2"-->
<!--										></ej-toggle-button>-->
<!--									</div>-->

<!--									<div class="d-flex align-items-center justify-content-end">-->
<!--										<label>Editar qualquer</label>-->
<!--										<ej-toggle-button-->
<!--											v-model.trim="tokensUpdateAny"-->
<!--											:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--											class="ml-2"-->
<!--										></ej-toggle-button>-->
<!--									</div>-->

<!--									<div class="d-flex align-items-center justify-content-end">-->
<!--										<label>Criar</label>-->
<!--										<ej-toggle-button-->
<!--											v-model.trim="tokensCreateAny"-->
<!--											:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--											class="ml-2"-->
<!--										></ej-toggle-button>-->
<!--									</div>-->

<!--									<div class="d-flex align-items-center justify-content-end">-->
<!--										<label>Deletar qualquer</label>-->
<!--										<ej-toggle-button-->
<!--											v-model.trim="tokensDeleteAny"-->
<!--											:labels="{checked: 'Sim', unchecked: 'Não'}"-->
<!--											class="ml-2"-->
<!--										></ej-toggle-button>-->
<!--									</div>-->
<!--								</div>-->
<!--							</div>-->
						</div>
					</div>

					<br>

					<div class="row d-flex justify-content-center">
						<ej-button
							@click="salvar"
							class="btn btn-secondary hvr-pulse"
							:disabled="saving"
						>
							<strong>Salvar  <font-awesome-icon :icon="['fas', 'cog']" spin v-if="saving"></font-awesome-icon></strong>
						</ej-button>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
//Mixins
import FetchingMixin from "@/mixins/FetchingMixin";
import Swal from "sweetalert2";
import {exibeErro, sanitize} from "@/helpers";

//Aux
import EjButton from "@/components/EjButton";
import {vueSet} from "vue-deepset";
// const _ = require('lodash');

const customComputed = function(id) {
	return {
		get() {
			return !! this.permissionsBruteModel.find((role) => role.id === id);
		},
		set(value) {
			if (value)
				this.permissionsBruteModel.push({id:id});
			else {
				this.permissionsBruteModel.splice(this.role.permissions.map((role) => role.id).indexOf(id), 1);
			}
		}
	}
};

export default {
	name: 'ProductEdit',
	components: {EjButton},

	data() {
		return {
			loadingRule: false,
			saving: false,
			operation: undefined,

			permissionsBrute: [],

			role: {
				name: undefined,
				brand: undefined,
				permissions: [],
				guard_name: 'web'
			},
		}
	},

	computed: {
		roleModel() {
			return this.$deepModel(this.role);
		},

		permissionsBruteModel() {
			return this.$deepModel(this.permissionsBrute);
		},

		spinner() {
			return this.loadingRule;
		},

		categoriesViewAny: customComputed(11), //categories.viewAny
		categoriesUpdateAny: customComputed(13), //categories.updateAny
		categoriesCreateAny: customComputed(40), //categories.createAny
		categoriesDeleteAny: customComputed(14), //categories.deleteAny

		citiesViewAny: customComputed(20), //cities.viewAny
		citiesViewOwn: customComputed(124), //cities.viewOwn
		citiesUpdateAny: customComputed(22), //cities.updateAny
		citiesCreateAny: customComputed(24), //cities.createAny
		citiesDeleteAny: customComputed(23), //cities.deleteAny

        statesViewAny: () => true, //states.viewAny
        statesUpdateAny: customComputed(119), //states.updateAny
        statesCreateAny: customComputed(121), //states.createAny
        statesDeleteAny: customComputed(120), //states.deleteAny

		rolesViewAny: customComputed(35), //roles.viewAny
		rolesUpdateAny: customComputed(37), //roles.updateAny
		rolesCreateAny: customComputed(39), //roles.createAny
		rolesDeleteAny: customComputed(38), //roles.deleteAny

		logViewAny: customComputed(41), //log.viewAny
		automessages: customComputed(129), //automessages

		monitoringAll: customComputed(42), //monitoring.all

		view_any_order: customComputed(77), //view_any_order
		view_your_own_orders: customComputed(78), //view_your_own_orders
        view_ps_internal: customComputed(128), //view_ps_internal

		update_orders_in_any_case: customComputed(79), //update_orders_in_any_case
		update_order_data_except_status_for_anyone: customComputed(97), //update_order_data_except_status_for_anyone
		update_order_data_except_status_for_yourself: customComputed(100), //update_order_data_except_status_for_yourself

		cancel_orders_for_anyone: customComputed(93), //cancel_orders_for_anyone
		cancel_budget_or_new_orders_for_anyone: customComputed(96), //cancel_budget_or_new_orders_for_anyone
		cancel_budget_or_new_orders_for_yourself: customComputed(104), //cancel_budget_or_new_orders_for_yourself

		delete_orders_in_any_case: customComputed(94), //delete_orders_in_any_case
		delete_budget_or_new_orders_for_anyone: customComputed(105), //delete_budget_or_new_orders_for_anyone
		delete_budget_or_new_orders_for_yourself: customComputed(95), //delete_budget_or_new_orders_for_yourself

		launch_orders_in_any_case: customComputed(116), //launch_orders_in_any_case
		launch_orders_with_status_released_for_anyone: customComputed(109), //launch_orders_with_status_released_for_anyone

		produce_orders_in_any_case: customComputed(114), //produce_orders_in_any_case
		produce_orders_with_status_launched_for_anyone: customComputed(115), //produce_orders_with_status_launched_for_anyone

		reject_orders_in_any_case: customComputed(101), //reject_orders_in_any_case
		reject_orders_with_status_new_or_released_for_anyone: customComputed(102), //reject_orders_with_status_new_or_released_for_anyone

		release_orders_in_any_case: customComputed(111), //release_orders_in_any_case
		release_orders_with_status_new_or_rejected_for_anyone: customComputed(112), //release_orders_with_status_new_or_rejected_for_anyone

		new_orders_for_anyone: customComputed(127), //new_orders_for_anyone
		update_orders_with_status_budget_to_new_for_anyone: customComputed(80), //update_orders_with_status_budget_to_new_for_anyone
		update_orders_with_status_budget_to_new_for_yourself: customComputed(81), //update_orders_with_status_budget_to_new_for_yourself
		update_orders_with_status_rejected_to_new_for_anyone: customComputed(86), //update_orders_with_status_rejected_to_new_for_anyone
		update_orders_with_status_rejected_to_new_for_yourself: customComputed(87), //update_orders_with_status_rejected_to_new_for_yourself

		ship_orders_in_any_case: customComputed(106), //ship_orders_in_any_case
		ship_orders_with_status_produced_for_anyone: customComputed(107), //ship_orders_with_status_produced_for_anyone

		deliver_orders_in_any_case: customComputed(126), //deliver_orders_in_any_case
		update_orders_with_status_shipped_to_delivered_for_anyone: customComputed(91), //ship_orders_with_status_produced_for_anyone

		peopleViewAny: customComputed(15), //people.viewAny
		peopleViewOwn: customComputed(16), //people.viewOwn
		peopleUpdateAny: customComputed(17), //people.updateAny
		peopleUpdateOwn: customComputed(52), //people.updateOwn
		peopleCreateAny: customComputed(19), //people.createAny
		peopleCreateOwn: customComputed(50), //people.createOwn
		peopleDeleteAny: customComputed(18), //people.deleteAny
		peopleDeleteOwn: customComputed(51), //people.deleteOwn

		productsViewAny: customComputed(1), //products.viewAny
		productsViewCost: customComputed(122), //products.viewCost
		productsViewStock: customComputed(123), //products.viewStock
		productsViewComposition: customComputed(125), //products.viewComposition
		productsUpdateAny: customComputed(3), //products.updateAny
		productsCreateAny: customComputed(9), //products.createAny
		productsDeleteAny: customComputed(4), //products.deleteAny

		usersViewAny: customComputed(30), //users.viewAny
		usersUpdateAny: customComputed(32), //users.updateAny
		usersCreateAny: customComputed(34), //users.createAny
		usersDeleteAny: customComputed(33), //users.deleteAny

		tokensViewAny: customComputed(25), //tokens.viewAny
		tokensUpdateAny: customComputed(27), //tokens.updateAny
		tokensCreateAny: customComputed(29), //tokens.createAny
		tokensDeleteAny: customComputed(28), //tokens.deleteAny

		settingsViewAny: () => true, //settings.viewAny
		settingsUpdateAny: customComputed(117), //settings.updateAny

        create_orders_for_anyone: customComputed(130), //create_orders_for_anyone
        create_orders_for_yourself: customComputed(131), //create_orders_for_yourself
	},

	mixins: [
		FetchingMixin
	],

	methods: {
		salvar() {
			const vm = this;

			this.roleModel['permissions'] = this.permissionsBruteModel.map(function(item) {
				return item.id;
			});

			this.saving = true;
			switch (this.operation) {
				case 'create':
					this.$store.dispatch(`roles/post`, sanitize(this.role))
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Grupo criado com sucesso!',
								'success'
							).then(function(result) {
								if (result.isConfirmed)
									window.location = '/roles';
							});
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
							vm.saving = false;
						});
					break;

				case 'edit':
					this.$store.dispatch(`roles/patch`, {
						id: this.role.id,
						data: sanitize(this.role)
					})
						.then(function() {
							Swal.fire(
								'Sucesso!',
								'Grupo atualizado com sucesso!',
								'success'
							);
						})
						.catch(function (error) {
							// handle error
							exibeErro(error);
						})
						.then(function () {
							// always executed
							vm.saving = false;
						});
					break;
			}
		}
	},

	mounted() {
		this.operation = this.$route.name === 'roles.edit' ? 'edit' : 'create';

		const vm = this;

		if (this.operation === 'edit') {
			this.loadingRule = true;
			this.$store.dispatch(`roles/get`, vm.$route.params.id)
				.then(function (response) {
                    vueSet(vm, 'permissionsBrute', response.data.permissions);
                    vueSet(vm, 'role', response.data);
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					vm.loadingRule = false;
				});
		}
	}
}
</script>

<style scoped>
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
	.content {
		padding: 10px 20px 20px 20px !important;
	}
}

.warn {
	color: #e63a3a;
}
</style>
